.react-datepicker-wrapper {
  width: 90% !important;
}

.pac-container{
  z-index: 1400;
}

.MuiAutocomplete-noOptions {
  display: none;
}

.MuiAutocomplete-inputRoot{
  height: 45px;
}

#textarea-disclaimers{
  -webkit-text-fill-color: rgb(14 14 14 / 79%)
}

.swal2-container {
  z-index: 1400;
}

.swal2-actions button{
   width: 100px;
}